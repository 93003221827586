<template>
  <div>
    <div class="heading-text">
      <span>{{ subtitle}}</span>
      <h3 class="mb-0" v-html="title"></h3>
    </div>
    <!--heading-text-->
  </div>
</template>

<script>
export default {
  name: "HeadingComponents",

  props: {
    title: String,
    subtitle:String
  },
};
</script>

<style scoped>
.heading-text {
  margin-bottom: 60px;
}
.heading-text span {
  display: inline-block;
  font-size: 18px;
  color: var(--grey);
  font-weight: 500;
  padding-bottom: 15px;
  font-family: "Poppins";
  text-transform: capitalize;
}

.heading-text h3 {
  text-transform: capitalize;
  font-size: 30px;
  color: var(--white);
  font-weight: 500;
}


</style>
