import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//sweet alert 
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { onHandleKeyPress, onHandlePaste, onHandleKeyUp, onHandleKeyDown } from './api/inputText';

const mixins = {
    methods: {
        // Handle the drag over event here
        handleDragOver:function(event) {
            event.preventDefault();
        },
        onHandleKeyPress,
        onHandlePaste,
        onHandleKeyUp,
        onHandleKeyDown,
        success: function (message) {
            this.$swal({
            title: message,
            position: "top-end",
            icon: "success",
            toast: true,
            timer: "3000",
            showConfirmButton: false,
            });
        },
        failed: function (message) {
            this.$swal({
            title: message,
            position: "top-end",
            icon: "error",
            toast: true,
            timer: "3000",
            showConfirmButton: false,
            });
        }
    }
}

createApp(App).use(VueSweetalert2).mixin(mixins).use(store).use(router).mount("#app");