<template>
    <div>
        <section class="banner-sec">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-11 col-xl-9 mx-auto">
                        <div class="banner-heading">


                            <h1 class="mb-0 pb-3" v-html="title" data-aos="fade-up"></h1>

                            <h3 class="mb-0 pb-4" v-html="para" data-aos="fade-up"></h3>

                            <div class="button-box" data-aos="fade-up" v-if="show_button">
                                <button class="btn btn-primary" @click="$router.push(routes)">
                                    {{ button_text }}
                                </button>
                            </div>
                        </div><!--banner-heading-->
                    </div><!--col-md-6 col-lg-6 col-xl-6-->
                </div><!--row-->
            </div><!--container-->

            <div class="banner-image">
                <div class="gradient-top"></div>
                <div class="gradient-sides"></div>
                <img :src="banner_image" alt="banner-image" class="img-fluid"  loading="lazy" />
            </div>
        </section><!--banner-sec-->


    </div>
</template>

<script>
export default {
    name: "BannerComponent",
    props: {
        title: String,
        gradient_title: String,
        banner_image: String,
        para: String,
        show_button: Boolean,
        button_text: String,
        routes:String

    },

    data() {
        return {

        }
    }
}

</script>

<style scoped>
.banner-sec {
    position: relative;
    overflow: hidden;
    padding-bottom: 0;
}

/**======BANNER HEADING  CSS START =========**/
.banner-heading {
    text-align: center;
    position: relative;
    z-index: 1;
}

.banner-heading h1 {
    font-size: 45px;
    font-weight: 600;
    line-height: 88px;
    color: var(--white);
    text-transform: uppercase;
}


.banner-heading h3 {
    font-size: 18px;
    color: var(--grey);
    line-height: 33px;
    font-weight: 500;
    font-family: 'Poppins';
}

/**======BANNER HEADING CSS FINISH =========**/


/**======BANNER IMAGE CSS START =========**/

.banner-image {
    text-align: center;
    margin-top: -100px;
    position: relative;
    overflow: hidden;
}

.banner-image img {
    max-width: 90vw;
    min-height: 500px;
    object-fit: cover;
}



.gradient-top {
    width: 100%;
    height: 200px;
    max-width: 100%;
    background-image: var(--gradient-top);
    position: absolute;
}

.gradient-sides {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: var(--gradient-sides);
}

/**======BANNER IMAGE CSS FINISH =========**/




.button-box .btn-primary {
    min-width: 150px;
    text-align: center;
    background-color: var(--gradient-bg);
    background-image: var(--gradient);
    border-radius: 50px;
    padding: 20px 40px;
    font-weight: 600;
    line-height: 120%;
    transition: opacity .3s;
    position: relative;
    border: none;
}



/**============ VALIDATOR BANNER SEC ============**/
.validator-sec .banner-sec .banner-image {
    margin-top: -230px;
}

/**============ VALIDATOR BANNER FINISH ============**/

/**============ GRANT BANNER SEC ============**/
.grant-banner .banner-sec .banner-image {
    margin-top: -230px;
}

/**============ GRANT BANNER SEC ============**/





@media all and (min-width: 992px) and (max-width: 1199px) {
    .banner-heading h1 {
        line-height: 85px;
    }


}

@media all and (min-width:768px) and (max-width:991px) {
    .banner-heading h1 {
        line-height: 75px;
        font-size: 35px;
    }

    .gradient-sides {
        display: none;
    }

    .banner-image img {
        min-height: auto;
        width: 160%;
        max-width: 160%;
        position: relative;
        z-index: -1;
        left: -275px;
    }
}



@media all and (min-width: 320px) and (max-width: 767px) {
    .banner-heading h1 {
        font-size: 18px;
    line-height: 33px;
    }

    .banner-heading h3 {
        font-size: var(--fs-18);
        line-height: 25px;
    }


    .gradient-sides {
        display: none;
    }

    .banner-image img {
        min-height: auto;
        width: 160%;
        max-width: 160%;
        position: relative;
        z-index: -1;
        left: -120px;
    }
}
</style>