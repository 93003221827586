<template>
  <div>
    <header class="head">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="container">
          <a class="navbar-brand" href="/"
            ><img src="../assets/logo.png" alt="logo" class="img-fluid"
          /></a>
          <button
            class="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="dropdownNavigation">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
              <li class="nav-item" @click="closeToggle">
                <router-link class="nav-link" to="/">Home </router-link>
              </li>
              <li class="nav-item" @click="closeToggle">
                <router-link class="nav-link" to="/ecosystem"
                  >Ecosystem
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  @mouseover="onOver"
                >
                  Participate
                </a>
                <div
                  class="dropdown-menu drop-card"
                  ref="dropdown"
                  @mouseleave="onLeave"
                >
                  <div class="drop-card-body">
                    <ul class="list">
                      <li class="mb-1" @click.prevent="onclosing()">
                        <router-link class="dropdown-item" to="/validator">
                          <span
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"
                              /></svg
                          ></span>

                          <h6 class="mb-0">
                            Become A Validator <br />
                            <span> Secure GDCC. Earn Rewards </span>
                          </h6>
                        </router-link>
                      </li>

                      <li @click.prevent="onclosing()">
                        <router-link class="dropdown-item" to="/delegator">
                          <span
                            ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8V444.8C394 378 431.1 230.1 432 141.4L256 66.8l0 0z"
                              /></svg
                          ></span>

                          <h6 class="mb-0">
                            Become A Delegator <br />
                            <span> Stake GDCC. Earn Rewards </span>
                          </h6>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="nav-item" @click="closeToggle">
                <a
                  class="nav-link"
                  target="_blank"
                  href="https://www.gdccscan.io/"
                  >Explorer</a
                >
              </li>
            </ul>

            <div class="button-box d-flex">
              <a href="http://tools.gdcchain.com/" target="_blank" @click="closeToggle" class="text-decoration-none">
                <button class="btn btn-primary" type="submit">
                  Deploy Your Token 
              </button>
              </a>

            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  methods: {
    onOver() {
      this.$refs.dropdown.classList.add("show");
    },
    
    onLeave() { 
      
      this.$refs.dropdown.classList.remove("show");
    },

    onclosing(){ 
      this.$refs.dropdownNavigation.classList.remove("show"); 
    },

    closeToggle() {
            const menuToggle = document.getElementById("navbarSupportedContent");
            menuToggle.classList.remove("show");
        }
  },
};
</script>

<style scoped>
.head {
  background: var(--black-bg);
  padding: 5px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
a.navbar-brand img {
  max-width: 130px;
}

ul.navbar-nav {
  gap: 30px;
}

ul.navbar-nav li.nav-item a.nav-link {
  color: var(--white);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 16px;
  text-transform: capitalize;
}

button.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.btn-primary {
    background-color: #ce1db4;
    border: none;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    min-height: 40px;
}

.btn-primary:hover {
  background-color: #ce1db4; 
}

.btn-primary:active {
  background-color: #ce1db4 !important;
}

/**====================== DROPDOWN CSS START ======================**/

.dropdown-menu.drop-card.show {
  min-width: 330px;
}
.drop-card {
  background: conic-gradient(
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0.06),
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.1),
    hsla(0, 0%, 100%, 0.2),
    hsla(0, 0%, 100%, 0.1)
  );
  box-shadow: 0 4px 10px 0 #000;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.251);
  padding: 2px;
  border-radius: 16px;
}

.drop-card-body {
  width: 100%;
  border-radius: 24px;
  background-color: var(--black);
  border-radius: 16px;
  padding: 8px;
}

ul.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.list li a {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  padding: 10px 10px;
  border-radius: 10px;
  transition: all 0.5s ease;
}

ul.list li a span svg {
  fill: var(--white);
}

ul.list li a h6 {
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 20px;
}

ul.list li a h6 span {
  color: var(--grey);
  font-weight: 600;
}

ul.list li a:hover {
  transition: all 0.5s ease;
  background: hsla(240, 4%, 95%, 0.15);
}

ul.list li a:focus {
  background: hsla(240, 4%, 95%, 0.15);
}

/**====================== DROPDOWN CSS FINISH ======================**/

@media all and (min-width: 768px) and (max-width: 991px) {
  ul.navbar-nav {
    gap: 10px;
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  ul.navbar-nav {
    gap: 10px;
  }
}
</style>
